import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class LayersRepository extends BaseRepository {
    baseUrl = 'management/bituminous-mixtures/layers';

    /**
     * Fetches the next order number
     *
     * @return {*}
     */
    fetchNextOrderNumber() {
        return this.httpClient.get(`${this.baseUrl}/next-order-number`);
    }

    /**
     * Returns all records for current specification
     *
     * @returns {*}
     */
    allBySector(criteria) {
        return this.httpClient.post('management/layers/all-by-sector', criteria);
    }

    /**
     * Reorders fields
     *
     * @param data
     *
     * @returns {*}
     */
    updateOrder(data) {
        return this.httpClient.put(`${this.baseUrl}/reorder`, data);
    }

    /**
     * Fetch the layer by material type
     *
     * @param materialTypeId
     * @return {*}
     */
    materialTypeLayer(materialTypeId) {
        return this.httpClient.get(`${this.baseUrl}/${materialTypeId}/layer`);
    }

    /**
     * Returns all records for current specification
     *
     * @returns {*}
     */
    allForCurrentSpecification() {
        return this.httpClient.get(`${this.baseUrl}/all-for-current-specification`);
    }
}
